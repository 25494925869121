import React, { useState } from 'react'
import { graphql, HeadProps } from 'gatsby'
import styled from 'styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { H2, LargeP, Layout, Grid, CareersMasthead, CareerBrowser, Button, Map, HeadTags } from '@components'

interface PageProps {
  data: Queries.CareersPageQuery
}

const CareersPage = ({ data: { sanityPage, allSanityJob, sanitySiteSettings } }: PageProps) => {
  if (!sanityPage || !allSanityJob) return <></>
  const { sprites, mapPositions } = sanityPage

  const jobsBoardLink = sanitySiteSettings?.socialLinks?.find(l => l?.networkName === 'Jobs Board')

  const [t, setT] = useState(0)
  useScrollPosition(
    ({ currPos }) => setT(currPos.y), // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )

  return (
    <Layout flushTop>
      <Grid>
        <CareersMasthead {...{ t }} sprites={sprites as Array<Queries.SanitySprite>}>
          <H2>
            Builders, dreamers, and
            <br />
            game–changers welcome
          </H2>
          <a href={jobsBoardLink?.link!} target="_blank" rel="noreferrer">
            <Button>View Job Positions</Button>
          </a>
        </CareersMasthead>

        <CareerBrowser jobs={[...(allSanityJob.nodes as Array<Queries.SanityJob>)]} link={jobsBoardLink?.link!} />

        <MidHeader>Where we are</MidHeader>
        <Map positions={mapPositions as Array<Queries.SanityMapPoint>} />

        <Together>
          <H2>Let’s pioneer, together.</H2>
          <LargeP>Be a part of the movement to reshape the future of commerce.</LargeP>
          <a href={jobsBoardLink?.link!} target="_blank" rel="noreferrer">
            <Button>View Job Postings</Button>
          </a>
        </Together>
      </Grid>
    </Layout>
  )
}

const MidHeader = styled(props => <H2 {...props} />)`
  grid-column: 1 / span 6;
  margin-top: 100px;
  @media only screen and (min-width: 944px) {
    grid-column: 1 / span 12;
    margin-top: 0;
  }
  text-align: center;
`

const Together = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 100px 0;
  @media only screen and (min-width: 944px) {
    margin: 0;
    grid-column: 1 / span 12;
  }
  text-align: center;
`

export const Head = (props: HeadProps) => (
  <HeadTags {...props}>
    <link rel="canonical" href="https://usepower.com/careers" />
  </HeadTags>
)

export const query = graphql`
  query CareersPage {
    sanitySiteSettings {
      socialLinks {
        link
        networkName
      }
    }
    allSanityJob {
      nodes {
        title
        category
        location
        link
      }
    }
    sanityPage(slug: { current: { eq: "careers" } }) {
      title
      slug {
        current
      }
      mapPositions {
        x
        y
        location
      }
      sprites {
        key
        altText
        image {
          asset {
            url
          }
        }
      }
    }
  }
`

export default CareersPage
